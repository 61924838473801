<template>
  <div style="width:100%">
    <StuCardList v-if="editType == 'list'"  @changeShowType="changeShowType"></StuCardList>
    <StuCardForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></StuCardForm>
    <StuCardInfo v-if="editType == 'show'" :editId="editId"    @changeShowType="changeShowType"></StuCardInfo>
  </div>
</template>

<script>
import StuCardList from './components/StuCardList'
import StuCardForm from './components/StuCardForm'
import StuCardInfo from './components/StuCardInfo'

export default {
  name: 'stuCard',
  components: { StuCardForm,StuCardList,StuCardInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>