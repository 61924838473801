<template>
  <div style="width:99%;margin:auto;">
    <div class="form-title">信息卡详情</div>
    <div class="approve">
      <div class="basic-info-container">
        <h3 class="basic-info">基础信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div class="grid-content1">年份：<span class="col-text">{{form.year}}</span></div>
            <div class="grid-content1">学期：<span class="col-text">{{form.semester=='1'?'春季学期':'秋季学期'}}</span></div>
            <div class="grid-content1">批次：<span class="col-text">{{form.batch}}</span></div>
            <div class="grid-content1">征订天数：<span class="col-text">{{form.subscriptionDayCount}}</span></div>
            <div class="grid-content1">征订日期：<span class="col-text">{{form.effectiveStartTime}}至{{form.effectiveEndTime}}</span></div>
            <div class="grid-content1">显示单价：<span class="col-text">{{form.isShowUnitPrice=='1'?'显示':'不显示'}}</span></div>
            <div class="grid-content1">编码：<span class="col-text">{{form.code}}</span></div>
            <div class="grid-content1">配送方式：<span class="col-text">{{form.deliverMode}}</span></div>
            <div class="grid-content1">温馨提示：<span class="col-text">{{form.reminder}}</span></div>            
            <div class="grid-content1">学校：<span class="col-text">{{form.school.schoolName}}</span></div>
            <div class="grid-content1">征订须知：<span class="col-text blue" @click="isShowNotice=true">查看</span></div>
            <div class="grid-content1">显示形式：<span class="col-text">{{form.noticeType=='1'?'常规式':'弹出式'}}</span></div>
            <div class="grid-content1">备注：<span class="col-text">{{form.remark}}</span></div>
          </div>
        </div>
      </div>
      <div class="basic-info-container">
        <h3 class="basic-info">套餐信息</h3>
        <div class="basic-info-content">
          <div style="display:flex;flex-wrap:wrap">
            <div style="width:780px; text-align:left;margin:auto;">
          
              <div class="package-box" v-for="(packageInfo,index) in form.packageList" :key="index">
                <div class="box-left">
                  <div class="package-info" >              
                    <div class="package-img" title="点击查看大图"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(packageInfo.thumbnailList).url"  @click.stop="showBigImage(getFirstFileByList(packageInfo.thumbnailList).url)"></el-image></div>
                    <div class="item" :title="packageInfo.name">套餐名称：{{packageInfo.name}}</div>
                    <div class="item">套餐单价：{{packageInfo.unitPrice}}元</div>
                    <div class="item">套餐优惠价：{{packageInfo.favorablePrice}}元</div>
                  </div>
                  <div class="combination-box">
                    <div class="combination-info" v-for="combinationInfo in packageInfo.combinationList" :key="combinationInfo.id">
                      <div class="combination-img"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(combinationInfo.thumbnailList).url"   @click.stop="showBigImage(getFirstFileByList(combinationInfo.thumbnailList).url)"></el-image></div>
                      <div class="item" :title="combinationInfo.name">组合名称：{{combinationInfo.name}}</div>
                      <div class="item">组合单价：{{combinationInfo.unitPrice}}元</div>
                      <div class="item">组合优惠价：{{combinationInfo.favorablePrice}}元</div>
                    </div> 

                  </div>
                </div>
                <div class="box-price">
                  <div class="price">
                    <span class="new-price">  ￥{{packageInfo.favorablePrice | multiplyWithPrecision(form.subscriptionDayCount)}} </span><br />
                    <span class="old-price">  ￥{{packageInfo.unitPrice | multiplyWithPrecision(form.subscriptionDayCount)}} </span>
                  </div>

                </div>
                <div class="box-right">
                  <div class="btn">
                    <el-button type="primary" title="查看限制年级" v-if="editType!='show'" size="small" icon="el-icon-setting" circle @click="setLimitGrade(packageInfo)"></el-button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
  

    </div>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
    </div>
    <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />
     <el-dialog :title="'限制年级'" :visible.sync="isShowSetGradeModal" :close-on-click-modal="false" width="400px" >
      <el-row>
          <div v-for="(gradeName,index) in currPackageInfo.limitGradeNameList" :key="index" style="line-height:25px;text-align:left;padding-left:150px;">
            <el-checkbox :label="gradeName" :checked="true" disabled ></el-checkbox>
          </div>
        <el-col :span="24" style="margin-top:10px;">
          <el-button @click="closeLimitGradeModal">关闭</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <Notice v-if="isShowNotice" v-model="isShowNotice"  :notice="form.notice" :eidt="false" ></Notice>
  </div>

</template>

<script>
import { getStuCardInfo } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Notice from '../../components/Notice'
export default {
  name: 'CustomerForm',
  components: { UploadImg,ElImageViewer,Notice },
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2'],
      form:{
        id:'',
        year:'',
        semester:'',
        subscriptionTypeId:'1',
        batch:'',
        effectiveEndTime:'',
        effectiveStartTime:'',
        isShowUnitPrice:'',
        subscriptionDayCount:'',
        remark:'',
        reminder:'',
        deliverMode:'',
        code:'',
        packageList:[],
        schoolIdsList:[],
        school:{},
        notice:'',
        noticeType:''
      },
      isShowNotice:false,
      disabled:false,
      imgDialogVisible:false,//图片预览
      bigImageUrl:'',
      addDate:[],
      isShowSetGradeModal:false,//设置年级弹框
      currPackageInfo:{}
    }
  },
  created() {
    this.disabled = this.editType=='show';
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getStuCardInfo();
    }
  },
  mounted() {
    
  },
  methods: {
    //返回
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    //查看大图
    showBigImage(url){
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    //关闭大图
    onClose() {
      this.imgDialogVisible = false
    },
    getFirstFileByList(list){
      if(list && list.length>0){
        return list[0];
      }
      return {};
    },
    setLimitGrade(packageInfo){
      this.currPackageInfo = packageInfo
      this.isShowSetGradeModal = true;
    },
    closeLimitGradeModal(){
      this.isShowSetGradeModal = false;
    },
  
    //编辑获取详情
    getStuCardInfo(){
      let param = {id:this.editId};      
      getStuCardInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;      
        this.addDate = [this.form.effectiveStartTime,this.form.effectiveEndTime]          
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/info.scss';

/**编辑套餐信息中的组合列表样式 */
.package-box{
  width:840px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.package-box .box-left{
  flex: 0 0 690px;
  border-right: 1px solid #C9E3FB;
}
.package-box .box-left .package-info{
  display: flex;
  border-bottom: 1px solid #C9E3FB;
  line-height: 70px;
  height: 70px;
}
.package-box .box-left .package-info .package-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  margin: auto;
  cursor: pointer;
  padding-left: 5px;
}
.package-box .box-left .package-info .item{
  flex: 1;
  text-align: left;
  line-height: 70px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.package-box .box-left .combination-box{
  padding-left:50px;
}
.package-box .box-left .combination-box .combination-info{
  display: flex;
  border-left: 1px solid #C9E3FB;
  line-height: 60px;
  height: 60px;
}

.package-box .box-left .combination-box .combination-info:not(:last-child){
  border-bottom: 1px solid #C9E3FB;
}


.package-box .box-left .combination-box .combination-info .combination-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  padding-left: 5px;
}
.package-box .box-left .combination-box .combination-info .item{
  flex: 1;
  text-align: left;
  line-height: 60px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}

.package-box .box-price{
  flex: 0 0 100px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #C9E3FB;
}

.package-box .box-price .price{
  margin: auto;
  text-align: center;
  padding-top:5px;
}

.package-box .box-price .price .new-price{
  margin: auto;
  text-align: center;
  padding-top:5px;
  font-size:16px;
  color:red;
}

.package-box .box-price .price .old-price{
  text-decoration:line-through;
}


.package-box .box-right{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.package-box .box-right .btn{
  margin: auto;
  text-align: center;
  padding-top:5px;
}


</style>
