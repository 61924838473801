<template>
  <div style="width:100%">
      <el-button type="primary" @click="editDetail('', 'add')" size="small">新增</el-button>
    <div class="search-box">
      <el-form inline size="small">
        <el-form-item label="年份：">
          <el-date-picker v-model="form.year" format="yyyy" value-format="yyyy" type="year" placeholder="请选择年份" style="width:100px"> </el-date-picker>
        </el-form-item>
        <el-form-item label="学期">
          <el-select v-model="form.semester" placeholder="请选择" style="width:100px">
              <el-option label="全部" value=""></el-option>
              <el-option label="春季学期" value="0"></el-option>
              <el-option label="秋季学期" value="1"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="征订批次"  >
            <el-select v-model="form.batch" placeholder="请选择" style="width:100px">
              <el-option  value="" label="全部" ></el-option>      
              <el-option  v-for="i in 10" :key="i" :value="i" :label="i"></el-option>   
            </el-select>
          </el-form-item>  
          <el-form-item label="状态"  >
            <el-select v-model="form.openStatus" placeholder="请选择" style="width:100px">
              <el-option  value="" label="全部" ></el-option>      
              <el-option  value="1" label="已开启"></el-option>   
              <el-option  value="0" label="未开启"></el-option>   
            </el-select>
          </el-form-item>  
          <el-form-item label="地区" >
            <ChooseArea ref="chooseArea" v-model="areaValue"  @changeArea="changeArea" :checkStrictly="true" :selectedLevel="4" style="width:250px" ></ChooseArea>
          </el-form-item>
          <el-form-item label="模糊搜索">
          <el-input v-model="form.search" placeholder="学校"></el-input>
          </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
        </el-form-item>

      </el-form>
    </div>
    <el-table :data="list" 
    :tree-props="{children: 'childrenList', hasChildren: 'hasChildren'}" 
    row-key="id" stripe
     style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column label="地区" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
         {{scope.row.cityName}}, {{scope.row.regName}}, {{scope.row.streetName}}
        </template>
      </el-table-column>
      <el-table-column label="年份/学期" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.year}}年, {{scope.row.semester==0?'春季学期':'秋季学期'}}
        </template>
      </el-table-column>
      <el-table-column label="学校" align="center" show-overflow-tooltip prop="schoolName"></el-table-column> 
      <el-table-column label="征订批次" align="center" show-overflow-tooltip prop="batch" width="80"></el-table-column> 
      <el-table-column label="征订天数" align="center" show-overflow-tooltip prop="subscriptionDayCount" width="80"></el-table-column> 
      <el-table-column label="征订时间" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          {{scope.row.effectiveStartTime}}至{{scope.row.effectiveEndTime}}
        </template>
      </el-table-column>

      <el-table-column label="创建时间" align="center" show-overflow-tooltip prop="createTime"></el-table-column>  
      <el-table-column label="状态" align="center" show-overflow-tooltip >
        <template slot-scope="scope">
          {{scope.row.openStatus=='0'?'未开启':'已开启'}}（{{scope.row.useStatus=='0'?'未使用':'已使用'}}）
        </template>
      </el-table-column>
     
      <el-table-column align="center" label="操作" width="260">
        <template slot-scope="scope">
          <el-button type="text"  @click="editDetail(scope.row.id,'show')">查看</el-button>
          <el-button v-if="scope.row.openStatus==0" type="text" @click="operateCardStatus(scope.row)">开启</el-button>
          <el-button v-if="scope.row.openStatus==1" type="text"  @click="operateCardStatus(scope.row)">关闭</el-button>
          
          <el-button v-if="scope.row.openStatus==0 && scope.row.useStatus==0" type="text"  @click="editDetail(scope.row.id,'edit')">编辑</el-button>
          <el-button v-if="scope.row.openStatus==0 && scope.row.useStatus==0" type="text"  style="color:red" @click="deleteOne(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="form.pageSize" :current-page="form.pageNum" :total="total"></el-pagination>
 
      
  </div>
</template>

<script>
import { Confirm } from '@/utils/common.js'
import ChooseArea from '@/components/ChooseArea/index.vue'
import { deleteStuCardInfo, getStuCardList,openOrCloseSubscription } from '@/api/index.js'
export default {
  name: 'capital',
  components: {ChooseArea},
  data() {
    return {
      showType: 0,
      editType: '',
      isAddDialog:false,
      form: {
        pageNum: 1,          //页码
        pageSize: 10,          //每页条数
        search: '',          //搜索条件
        year:'',
        semester:'',
        batch:'',
        subscriptionTypeId:1,
        proId:'',
        cityId:'',
        regId:'',
        streetId:'',
        openStatus:''

      },
      list: [],
      total: 0,
      editRow: '',
      editType: '',
      addDate:[],
      areaValue:['','','',''],
    }
    
  },
  created() {
    let nowDate = new Date();
    this.form.year = nowDate.getFullYear()+'';
    let currMonth = nowDate.getMonth()+1;
    if(currMonth>6){
      this.form.semester = '1';
    }else{
      this.form.semester = '0';
    }
    
    this.getList();
  },
  methods: {
    editDetail(id, editType) {
      this.$emit('changeShowType', editType,id)
    },
    getList() {
      if(this.addDate.length>0){
        this.form.startTime=this.addDate[0];
        this.form.endTime=this.addDate[1];
      }else{
        this.form.startTime='';
        this.form.endTime='';
      }
      getStuCardList(this.form).then(res => {
        if (res.code != 200) return
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.getList()
    },
    deleteOne(id) {
      Confirm('您确定删除该信息卡吗?', () => {
        deleteStuCardInfo({ ids:id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '删除失败')
            return
          }
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
    operateCardStatus(cardInfo){
      let warnMsg = cardInfo.openStatus==0?'您确认开启信息卡？':'您确认关闭信息卡？';
      Confirm(warnMsg, () => {
        openOrCloseSubscription({ ids:cardInfo.id }).then(res => {
          if (res.code != 200) {
            this.$message.warning(res.msg || '操作失败')
            return
          }
          this.$message.success('操作成功')
          this.getList()
        })
      })
    },
    changeArea(selectedData){
      this.form.proId = selectedData.proId;
      this.form.cityId=selectedData.cityId;
      this.form.regId=selectedData.regId;
      this.form.streetId=selectedData.streetId;
    },
  }
}
</script>

<style>
</style>